import * as $ from "jquery";
import * as Cookies from "js-cookie";

export class Alert {

    static init() {
        $('body').on('click', '.alert-notify.alert-dismissible .alert-close',
            (event) => {
                const $alert = $(event.target).parents('.alert-notify');
                Cookies.set($alert.attr('id'), '1', {expires: $alert.data('expires')});
            });
    }

}