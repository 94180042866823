declare var toastr: any;

export class Notification {

    static toast(message, type) {
        toastr[type](message);
    }

    static error(message) {
        toastr['error'](message);
    }

    static success(message) {
        toastr['success'](message);
    }

    static info(message) {
        toastr['info'](message);
    }

    static warning = function (message) {
        toastr['warning'](message);
    }
}